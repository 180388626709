<template>
  <div class="px-2 mt-1">
     <header-slot>
      <template #actionsButtons>
        <portal-target name="actionsButtons_header" />
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeRecomendationPending }"
        exact
         exact-active-class="active"
        :active="isPendingTab"
        :link-classes="['px-3', bgTabsNavs]"
      >
        PENDING
      </b-nav-item>
        <b-nav-item
        :to="{ name: $route.matched[1].meta.routeRecomendationApproved }"
        exact
         exact-active-class="active"
        :active="isApprovedTab"
        :link-classes="['px-3', bgTabsNavs]"
      >
        APPROVED
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeRecomendationRejected }"
        exact
         exact-active-class="active"
        :active="isRejectedTab"
        :link-classes="['px-3', bgTabsNavs]"
      >
        REJECTED
      </b-nav-item>
    </b-nav>

    <b-card no-body  class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {
  computed: {
    isPendingTab() {
      const { routeRecomendationPending } = this.$route.matched[1].meta;
      return routeRecomendationPending == this.$route.name;
    },

    isApprovedTab(){
        const { routeRecomendationApproved } = this.$route.matched[1].meta;
        return routeRecomendationApproved == this.$route.name;
    },

    isRejectedTab() {
      const { routeRecomendationRejected } = this.$route.matched[1].meta;
      return routeRecomendationRejected == this.$route.name;
    },

  },
};
</script>

